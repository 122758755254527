import React, { useEffect, useState } from "react";

import { useSessionStorageState } from "ahooks";
import { Button, Col, Row } from "antd";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import * as styles from "./index.module.scss";

import Header from "@components/header";

import Footer from "@components/footer";

const Complaints = ({ data }) => {
  console.log(data);
  const [Ismerchants, setMerchants] = useState(true);
  //数据回显功能使用ahooks 实现
  const [fields, setFields] = useSessionStorageState();
  useEffect(() => {
    setFields(undefined);
  }, []);
  return (
    <div className={styles.complaint}>
      <Header />
      <div className={styles.title}>
        <h1>投诉建议</h1>
        <h4>Complaints & Suggestions</h4>
      </div>
      <Row>
        <Col xs={0} sm={0} md={24}>
          <div className={styles.content}>
            <div className={styles.contentItem}>
              <Img
                fluid={data.pcImg1.childImageSharp.fluid}
                className={styles.img}
              />
              <div className={styles.whoamI}>我是商家</div>
              <div className={styles.buttonContainer}>
                <Button className={styles.button} shape="round">
                  <Link to="/complaints/trademark">商标被侵权</Link>
                </Button>
                <Button className={styles.button} shape="round">
                  <Link to="/complaints/copyright">版权被侵权</Link>
                </Button>
              </div>
            </div>
            <div className={styles.contentItem}>
              <Img
                fluid={data.pcImg2.childImageSharp.fluid}
                className={styles.img}
              />
              <div className={styles.whoamI}>我是消费者</div>
              <div className={styles.buttonContainer}>
                <Button className={styles.button} shape="round">
                  <Link to="/complaints/consumers">投诉商家</Link>
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={0}>
          <div className={styles.smallContent}>
            {/* this is small contentItem */}
            <div className={styles.toggleGroup}>
              <div
                className={[
                  styles.toggle,
                  Ismerchants ? styles.toggleSelected : "",
                ].join(" ")}
                onClick={() => {
                  setMerchants(true);
                }}
              >
                我是商家
              </div>
              <div
                className={[
                  styles.toggle,
                  !Ismerchants ? styles.toggleSelected : "",
                ].join(" ")}
                onClick={() => {
                  setMerchants(false);
                }}
              >
                我是消费者
              </div>
            </div>
            <Img
              fluid={
                Ismerchants
                  ? data.pcImg1.childImageSharp.fluid
                  : data.pcImg2.childImageSharp.fluid
              }
              className={styles.smallImg}
            />
            <div className={styles.smallButtonContaier}>
              {Ismerchants ? (
                <>
                  <Button className={styles.button} shape="round">
                    <Link to="/complaints/trademark">商标被侵权</Link>
                  </Button>
                  <Button className={styles.button} shape="round">
                    <Link to="/complaints/copyright">版权被侵权</Link>
                  </Button>
                </>
              ) : (
                <Button className={styles.button} shape="round">
                  <Link to="/complaints/consumers">投诉商家</Link>
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Footer />
    </div>
  );
};

export default Complaints;

export const query = graphql`
  query {
    pcImg1: file(relativePath: { eq: "complaints/pcImg1.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 210) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pcImg2: file(relativePath: { eq: "complaints/pcImg2.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 210) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
