// extracted by mini-css-extract-plugin
export var button = "index-module--button--PjJJM";
export var buttonContainer = "index-module--buttonContainer--jWUN9";
export var complaint = "index-module--complaint--y0zGB";
export var content = "index-module--content--B1yMi";
export var contentItem = "index-module--contentItem--ifyf4";
export var img = "index-module--img--jVjLv";
export var smallButtonContaier = "index-module--smallButtonContaier--NOqDe";
export var smallContent = "index-module--smallContent--axiys";
export var smallImg = "index-module--smallImg--4Ysug";
export var title = "index-module--title--punTQ";
export var toggle = "index-module--toggle--tJD5c";
export var toggleGroup = "index-module--toggleGroup--hhuLj";
export var toggleSelected = "index-module--toggleSelected--CLodz";
export var whoamI = "index-module--whoamI--Iv2tT";